import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { InhouseQuestion, Quiz, QuizAnswer } from '../models/quiz.model';
import { environment } from 'src/environments/environment';
import { readQueryParameters } from '../shared-functions';
import { map } from 'rxjs/operators';
import { JobApplication } from '../models/job-application.model';
import { TranslateService } from '@ngx-translate/core';
import { PersonalityTest, PersonalityTestAnswers, PersonalityTestResults } from '../models/personality-test.model';
import { Pagination } from '../models/pagination.interface';
import { QuizStoreService } from './quiz-store.service';
import { UniversalJobEditPayload } from '../models/universa-job.model';
import { IApplication } from '../models/application.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  private _questionAnswer$: Subject<{ quizAnswer: QuizAnswer, response: any }> = new Subject<{ quizAnswer: QuizAnswer, response: any }>();

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private configService: AppConfigService,
    private quizStore: QuizStoreService
  ) { }

  get questionAnswered$(): Observable<{quizAnswer: QuizAnswer, response: any}> {
    return this._questionAnswer$.asObservable();
  }

  setQuestionAnswered(answerData: QuizAnswer, response: any): void {
    if (answerData.answer === null && answerData.questionId === null) {
      this._questionAnswer$.next({quizAnswer: null, response: null});
    } else {
      this._questionAnswer$.next({quizAnswer: answerData, response});
    }
  }

  getInhouseAndKnockoutQuestions(): Observable<Quiz> {
    return forkJoin([
      this.getInhouseQuestion(),
      this.getKnockoutQuestions()
    ]).pipe(
      map(([inhouseQuestion, knockoutQuestions]) => {
        if (Object.keys(inhouseQuestion).length) {
          inhouseQuestion.question.isInhouseQuestion = true;

          if (knockoutQuestions && knockoutQuestions.questions) {
            knockoutQuestions.questions.unshift(inhouseQuestion.question);
            knockoutQuestions.passAllInhouseCandidates = inhouseQuestion.passAllInhouseCandidates;
            knockoutQuestions.inhouseQuestionIncluded = true;
          } else {
            knockoutQuestions = {
              passAllCandidates: true,
              questions: [inhouseQuestion.question],
            };
          }
        }

        return Object.keys(knockoutQuestions).length ? knockoutQuestions : null;
      })
    );
  }

  getKnockoutQuestions(): Observable<Quiz> {
    const jobGUID = readQueryParameters('job');
    const headers = new HttpHeaders({ 'Accept-language': this.translateService.currentLang });

    return this.http
      .get(`${environment.jobs}/${jobGUID}/knockout_questions`, { headers });
  }

  checkIfKnockoutQuestionAnswerIsCorrect(answerIndex: number): Observable<boolean> {
    const jobGUID = readQueryParameters('job');
    const headers = new HttpHeaders({ 'Accept-language': this.translateService.currentLang });

    let params = new HttpParams();

    const { activeQuestionIndex, inhouseQuestionIncluded } = this.quizStore.quiz;

    const questionIndex = inhouseQuestionIncluded
      ? activeQuestionIndex - 1
      : activeQuestionIndex;

    params = params.append('questionNumber', questionIndex.toString());
    params = params.append('selectedAnswer', answerIndex.toString());

    return this.http.get<boolean>(`${environment.jobs}/${jobGUID}/knockout_questions/is_answer_correct`, { params, headers });
  }

  getInhouseQuestion(): Observable<InhouseQuestion> {
    const jobGUID = readQueryParameters('job');
    const headers = new HttpHeaders({ 'Accept-language': this.translateService.currentLang });

    return this.http.get<InhouseQuestion>(`${environment.inhouseQuestion}/${jobGUID}`, { headers });
  }

  checkIfInhouseQuestionAnswerIsCorrect(answerIndex: number): Observable<boolean> {
    const jobGUID = readQueryParameters('job');
    const headers = new HttpHeaders({ 'Accept-language': this.translateService.currentLang });

    let params = new HttpParams();
    params = params.append('selectedAnswer', answerIndex);

    return this.http.get<boolean>(`${environment.inhouseQuestion}/is_answer_correct/${jobGUID}`, { params, headers });
  }

  submitBusinessQuestionAnswer(selectedAnswers: number[]): Observable<JobApplication> {
    const applicationGUID = readQueryParameters('application');

    return this.http
      .put<JobApplication>(`${environment.applications}/${applicationGUID}/save_business_question_answer`, {
        questionNumber: this.quizStore.activeQuestionIndex,
        selectedAnswers: selectedAnswers
      });

  }

  submitPersonalityTestAnswer(answerIndex: number, questionId: number): Observable<PersonalityTestAnswers> {
    const applicationGUID = readQueryParameters('application');

    return this.http.post<PersonalityTestAnswers>(`${environment.psykometrika}/${applicationGUID}/answer`, {
      answer1: answerIndex + 1,
      questionId
    });
  }

  checkIfPersonalityTestResultsExist(candidateGUID: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.candidates}/${candidateGUID}/personality_test`);
  }

  keepPersonalityTestResults(): Observable<JobApplication> {
    const applicationGUID = readQueryParameters('application');

    return this.http.post<JobApplication>(`${environment.applications}/${applicationGUID}/use_personality_test`, {});
  }

  getPersonalityTest(): Observable<PersonalityTest> {
    const guid = readQueryParameters('application');
    return this.http.get<PersonalityTest>(`${environment.psykometrika}/${guid}/test`);
  }

  getPersonalityTestResults(): Observable<PersonalityTestResults> {
    const guid = readQueryParameters('application');
    return this.http.get(`${environment.psykometrika}/${guid}/results`);
  }

  getPersonalityTestAnswers(): Observable<PersonalityTestAnswers> {
    const guid = readQueryParameters('application');
    return this.http.get<PersonalityTestAnswers>(`${environment.psykometrika}/${guid}/answers`);
  }

  editUniversalTest(payload: UniversalJobEditPayload): Observable<IApplication> {
    const guid = readQueryParameters('application');
    return this.http.put<IApplication>(`${environment.applications}/${guid}/business_and_video_questions`, payload);
  }

  setCandidateisNotInterested(): Observable<string> {
    const guid = readQueryParameters('application');
    return this.http.put<string>(`${environment.applications}/${guid}/candidate_not_interested`, null);
  }
}
